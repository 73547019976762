import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/ichwan/Documents/repos/new-personal-site/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const Divider = makeShortcode("Divider");
const Text = makeShortcode("Text");
const Link = makeShortcode("Link");
const SourceList = makeShortcode("SourceList");
const Flex = makeShortcode("Flex");
const Box = makeShortcode("Box");
const Card = makeShortcode("Card");
const Heading = makeShortcode("Heading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Image sx={{
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '50%',
      transform: 'scale(0.75)',
      borderRadius: '50%'
    }} src='https://lh3.googleusercontent.com/pw/ACtC-3fIcQRQ_uDA52rUQ5iFeMWh6J822nF_Zl1pS0geEv78yyjieoyVRv3S92MUeCplfrPtYNfxVyck9vxWraP31ybEi4k9IogRPBNPICzpNtsaSCjY5Nt7h-KTERPKVHpg9D8bdntnzKjAgEpHV2AoKnBzcg=s1272-no?authuser=0' mdxType="Image" />
    <h1>{`About Me`}</h1>
    <p>{`Hi y'all, my name is Ichwan!`}</p>
    <p>{`I'm currently working as Software Test Engineer at `}<a parentName="p" {...{
        "href": "https://axoni.com"
      }}>{`Axoni`}</a>{`, an enterprise blockchain company here in New York City.
I graduated from the City College of New York with Bachelor of Science (BSc) in Computer Science and minor in Economics. I am experienced
in backend development, database design, data scrapings, and automated testing. My favorite language  is Python, but I'm always down to
pick up new ones to get things done 😄`}</p>
    <p>{`My interest are on full-stack development and everything data, more specifically the backend development and data engineering, respectively.
My current focus is on learning Scala, a functional programming language, which happens to be at the intersection of my interests and work.`}</p>
    <p>{`Feel free to `}<a parentName="p" {...{
        "href": "/contacts"
      }}>{`Contact Me`}</a>{` if you want to connect with me.`}</p>
    <Divider mdxType="Divider" />
    <h1>{`Experience`}</h1>
    <p>{`For the past five years, I've had the opportunities of interning in four different companies before joining Axoni,
my current `}<em parentName="p"><em parentName="em">{`and`}</em></em>{` first full-time position. `}</p>
    <p>{`I've listed three of my most recent positions below and you can find out more details about them and other positions
on the `}<a parentName="p" {...{
        "href": "/experience"
      }}>{`Experience + Resume`}</a>{` section of this site.`}</p>
    <Divider mdxType="Divider" />
    <Text mdxType="Text"><Link href="/experience#ax" mdxType="Link">Software Test Engineer at Axoni</Link></Text>
    <Text variant='styles.small' mdxType="Text">Apr 2019 - Present</Text>
    <Divider mdxType="Divider" />
    <Text mdxType="Text"><Link href="/experience#fp" mdxType="Link">Software Engineering Intern at Flashpoint</Link></Text>
    <Text variant='styles.small' mdxType="Text">Oct 2018 - Jan 2019</Text>
    <Divider mdxType="Divider" />
    <Text mdxType="Text"><Link href="/experience#ek" mdxType="Link">Software Developer Intern at Enerknol</Link></Text>
    <Text variant='styles.small' mdxType="Text">Jun 2018 - Aug 2020</Text>
    <Divider mdxType="Divider" />
    <h1>{`Projects`}</h1>
    <p>{`The following are a selection of projects that I've worked on over the years.`}</p>
    <SourceList filter="projects" mdxType="SourceList">
  {projects => <Flex sx={{
        flexWrap: "wrap",
        ml: theme => `-${theme.space[2]}px`,
        mr: theme => `-${theme.space[2]}px`
      }} mdxType="Flex">
      {projects.filter(edge => edge.node.frontmatter.pinned).map((edge, index) => {
          const {
            frontmatter: {
              title,
              date,
              url,
              misc
            },
            fields: {
              slug
            }
          } = edge.node;
          return <Box key={index} sx={{
            mb: 3,
            maxWidth: ["100%", "50%", "33.333%"],
            width: ["100%", "50%", "33.333%"]
          }} mdxType="Box">
            <Link href={slug} sx={{
              textDecoration: 'none'
            }} mdxType="Link">
              <Card sx={{
                ml: 2,
                mr: 2,
                p: 3
              }} mdxType="Card"> 
                <Text sx={{
                  fontSize: 0,
                  color: 'muted'
                }} mdxType="Text">{misc}</Text>
                <Heading variant="styles.h4" sx={{
                  color: 'text'
                }} mdxType="Heading">{title}</Heading>
                <Text sx={{
                  textDecoration: 'underline'
                }} mdxType="Text">View project</Text>
              </Card>
            </Link>
          </Box>;
        })}
    </Flex>}
    </SourceList>
    <Divider mdxType="Divider" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      